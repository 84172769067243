import styled from "styled-components";
import { Card } from "react-bootstrap";

export const PubMaterial = styled.a`
  color: #000;
  text-decoration: none;
`;

export const SemesterName = styled(Card.Title)`
  margin-top: 10px;  
  color: #d41b2c;
  font-size: 21px;
  padding-left: 12px;
  &:hover {
    text-decoration: underline;
  }
`;

export const CourseName = styled(Card.Subtitle)`
  margin-top: 0;
  color: #FFF;
  padding-left: 30px;
  font-size: 14px;
  margin-bottom: 10px;
`;
