import React from "react";
import { StyledTitle } from "../../styles";
import { Row, Col } from "react-bootstrap";
import Citation from "../../components/Citation";
import { Link } from "react-router-dom";
import { PersonLinks } from "../../components/PersonLink/PersonLink";
import { StyledLink } from "../../styles";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import NotFound from "../NotFound";

const About = () => {
  return (
    <div className="article-content">
      <StyledTitle>Tenure Materials for Letter Writers</StyledTitle>
      <p>
        This page provides materials intended for the writers of tenure
        recommendation letters on my behalf: my CV, statements, and 5 selected
        publications.
      </p>
      <div style={{ width: "100%" }}>
        <ul>
          <li>
            <StyledLink href="/dunne_cv.pdf">Curriculum Vitae (CV)</StyledLink>
          </li>
          <li>
            <StyledLink href="/tenure/dunne_research_statement.pdf">
              Research Statement
            </StyledLink>
          </li>
          <li>
            <StyledLink href="/tenure/dunne_teaching_statement.pdf">
              Teaching Statement
            </StyledLink>
          </li>
          <li>
            <StyledLink href="/tenure/dunne_service_statement.pdf">
              Service Statement
            </StyledLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

const SelectedPublications = ({ papers, people }) => {
  // Filter to just selected papers
  let selectedIDs = [
    "DiBartolomeo2021StratisfimalLayoutModular",
    "Leventidis2020QueryvisLogicBased",
    "Zhang2019IdmvisTemporalEvent",
    "Saffo2021RemoteCollaborativeVirtual",
    "Panavas2022JuvenileGraphicalPerception",
  ];
  papers = papers.filter((paper) => selectedIDs.includes(paper.urlId));

  let recPub = papers.sort((a, b) => {
    return b.year - a.year;
  });
  return (
    <>
      <StyledTitle>Selected Publications</StyledTitle>
      <p>
        These selected publications are meant to represent the breadth of my
        research and I focus on recent work done with my PhD students at
        Northeastern. See my <Link to="/pubs/">Publications</Link> page,{" "}
        <a href="https://scholar.google.com/citations?user=qDjyCz8AAAAJ">
          Google Scholar
        </a>
        , and <StyledLink href="/dunne_cv.pdf">my CV</StyledLink> for a complete
        listing.
      </p>
      {recPub.map((pub, index) => (
        <Citation key={index} pub={pub} people={people} />
      ))}
    </>
  );
};

const Tenure = ({ papers, people }) => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <Row>
          <Row>
            <Col sm={3}>
              <img
                src={process.env.PUBLIC_URL + "/cody_dunne.jpg"}
                width="100%"
                className="d-inline-block align-top portrait"
                alt="Data Vis logo"
                style={{ paddingBottom: "15px" }}
              />
              <PersonLinks people={people} personId={"Cody-Dunne"} />
            </Col>
            <Col sm={9}>
              <Row>
                <About />
              </Row>
              <SelectedPublications
                papers={papers.publications}
                people={people}
              />
            </Col>
          </Row>
        </Row>
      </Route>
      <Route path="*">
        <title>Cody Dunne</title>
        <meta
          name="description"
          content="404 not found - Cody Dunne in the Khoury Vis Lab, Northeastern University"
        />
        <NotFound />
      </Route>
    </Switch>
  );
};

export default Tenure;
