import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { Card, Row } from "react-bootstrap";
import {
  StyledCard,
  PersonName,
  PersonTitle,
  PersonDescription,
  StyledLink,
  PeopleCol,
} from "./styles";
import { StyledTitle } from "../../styles";
import Person from "./Person";

const PeopleCard = ({ person, section }) => {
  return (
    <PeopleCol>
      <StyledCard>
        {person.image && (
          <Card.Img
            variant="top"
            src={process.env.PUBLIC_URL + "/kvsm/Image/people/" + person.image}
          />
        )}
        <Card.Body>
          <PersonName>{person.name}</PersonName>
          <PersonTitle>{person.title}</PersonTitle>
          <PersonDescription>{person.description}</PersonDescription>
        </Card.Body>
      </StyledCard>
    </PeopleCol>
  );
};

const Position = ({ people }) => {
  // Filter to just Cody and Cody's people
  let filteredPeople = {}
  Object.entries(people).forEach(([personType, personArray]) => {
    filteredPeople[personType] = personArray.filter(person => (person.name === "Cody Dunne") || (person.supervisors && person.supervisors.includes("Cody Dunne")))
  }
  );

  delete filteredPeople["Faculty"]
  delete filteredPeople["Friends & collaborators @ Khoury"]

  let combinedFolks = [];
  combinedFolks.push(...filteredPeople["Research Staff"]);
  combinedFolks.push(...filteredPeople["PhD Students"]);
  delete filteredPeople["Research Staff"]
  delete filteredPeople["PhD Students"]

  filteredPeople = Object.assign({ "Research Staff and PhD Students": combinedFolks }, filteredPeople);
  people = filteredPeople;

  return Object.entries(people).map((pair, index) => (
    <React.Fragment key={pair[0]}>
      {/* <Container key={index}> */}
      <hr />
      <StyledTitle id={pair[0]}>{pair[0]}</StyledTitle>
      <Row xs={1} md={4}>
        {pair[1].map((person, index) => (
          <StyledLink
            key={index}
            href={
              process.env.REACT_APP_KVSM_SITE +
              "/people/" +
              person.name.trim().replaceAll(" ", "-") +
              "/"
            }
          >
            <PeopleCard person={person} section={pair[0]} />
          </StyledLink>
        ))}
      </Row>
      {/* </Container> */}
    </React.Fragment>
  ));
};

const People = ({ papers, people, peopleDict }) => {
  let { path } = useRouteMatch();

  const roles = Object.keys(people);

  // advisor to role to array of names
  const advisorToPeople = Object.fromEntries(
    people["Faculty"].map((person) => [
      person.name,
      Object.fromEntries(roles.map((role) => [role, []])),
    ])
  );

  Object.entries(people).forEach(([role, persons]) => {
    persons.forEach((person) => {
      if (person.supervisors) {
        person.supervisors.forEach((supervisorName) => {
          advisorToPeople[supervisorName][role].push(person.name);
        });
      }
    });
  });

  return (
    <Switch>
      <Route exact path={path}>
        <Position people={people} />
      </Route>
      <Route path={`${path}/:personId`}>
        <Person
          advisorToPeople={advisorToPeople}
          peopleDict={peopleDict}
          papers={papers}
        />
      </Route>
    </Switch>
  );
};

export default People;
