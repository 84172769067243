import styled from "styled-components";
import { Figure, Toast } from "react-bootstrap";

export const StyledHeading = styled.div`
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const StyledPre = styled.pre`
  display: block;
  font-family: Courier New, monospace;
  font-size: 12px;
  line-height: 22px;
  white-space: pre-wrap;
  margin: 1em 0px;
`;

export const StyledFigure = styled(Figure)`
  margin-bottom: 0;
`;

export const FigureImageHolder = styled.div`
  padding: 15px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #FFFFFF;
`;

export const FigureImage = styled(Figure.Image)`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const StyledFigureCaption = styled(Figure.Caption)`
  color: #EEEEEE;
`;

export const StyledToast = styled(Toast)`
  position: absolute;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`;

export const StyledPaperToast = styled(Toast)`
  text-align: center;
  width: auto;
  border: none;
  box-shadow: none;
`;

export const StyledPaperToastBody = styled(Toast.Body)`
  padding: 0;
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

export const StyledYear = styled.h5`
  color: #e11a2c;
  font-size: 25px;
  padding-left: 4px;
`;
