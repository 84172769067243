import styled from "styled-components";
import { Image, Row } from "react-bootstrap";

export const StyledImage = styled(Image)`
  transition: transform 0.2s;
  width: 100%;
  object-fit: cover;
  &:hover {
    cursor: pointer;
    -moz-box-shadow: 0 0 10px #ffbf3d;
    -webkit-box-shadow: 0 0 10px #ffbf3d;
    box-shadow: 0 0 10px #ffbf3d;
  }
`;

export const PubTitle = styled.a`
  font-weight: 600;
  color: #FFFFFF;
  text-decoration: none;
  &:hover {
    color: #ffbf3d;
    text-decoration: none;
  }
`;
export const PubRow = styled(Row)`
  padding: 10px 0px;
`;
