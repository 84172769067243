import React from "react";
import { Card, Row } from "react-bootstrap";
import { StyledCard, StyledCol, StyledCardBody, StyledA } from "./style";
import Identicon from "react-identicons";

const PersonCard = ({ name, people, showImage }) => {
  if (name in people) {
    let person = people[name];
    let pageLink =
      process.env.PUBLIC_URL +
      "/people/" +
      person.name.trim().replaceAll(" ", "-") +
      "/";
    return (
      <StyledA href={pageLink}>
        <StyledCard>
          {showImage &&
            (person.image ? (
              <Card.Img
                variant="top"
                src={process.env.PUBLIC_URL + "/kvsm/Image/people/" + person.image}
              />
            ) : (
              <Identicon string={person.name} size={160} />
            ))}
          <StyledCardBody>{person.name}</StyledCardBody>
        </StyledCard>
      </StyledA>
    );
  } else {
    return (
      <StyledCard>
        {showImage && <Identicon string={name} size={160} />}
        <StyledCardBody>
          <div>{name}</div>
        </StyledCardBody>
      </StyledCard>
    );
  }
};

const NameCards = ({ names, people, showImage = true }) => {
  return (
    <Row xs="auto" md="auto" lg="auto">
      {names.map((name, index) => (
        <StyledCol key={index}>
          <PersonCard name={name} people={people} showImage={showImage} />
        </StyledCol>
      ))}
    </Row>
  );
};

export default NameCards;
