import React, { useState } from "react";
import { Switch, useParams, Route, useRouteMatch } from "react-router-dom";

import { StyledTitle } from "../../styles";
import { StyledLink } from "../../styles";
import { Figure, Button, Toast, Stack } from "react-bootstrap";
import {
  StyledHeading,
  FigureImageHolder,
  FigureImage,
  StyledFigure,
  StyledFigureCaption,
  StyledPre,
  StyledToast,
  StyledPaperToast,
  StyledPaperToastBody,
  StyledYear,
} from "./styles";
import Citation from "../../components/Citation";
import Materials from "../../components/Materials";
import NameCards from "../../components/NameCards";
import NotFound from "../../pages/NotFound";
import { ImCopy } from "react-icons/im";
import { MdCheckCircle } from "react-icons/md";
import { Helmet } from "react-helmet";

const Paper = ({ papers, people }) => {
  let { paperId } = useParams();
  let paper = papers.find((p) => p.urlId === paperId);

  if (paper === undefined) {
    return <NotFound />;
  }

  return (
    <Stack gap={3}>
      <h3>{paper.title}</h3>

      <Helmet>
        <title>
          {paper.title} - Khoury Vis Lab, Northeastern University
        </title>
        <meta
          name="description"
          content={
            paper.title +
            " - Khoury Vis Lab, Northeastern University"
          }
        />
      </Helmet>

      <StyledFigure>
        <FigureImageHolder>
          <FigureImage
            src={process.env.PUBLIC_URL + "/kvsm/Image/paper/figure/" + paper.image}
            alt={paper.altText}
          />
        </FigureImageHolder>
        <StyledFigureCaption>{paper.imageDesc}</StyledFigureCaption>
      </StyledFigure>

      <div>
        <StyledHeading>Abstract</StyledHeading>
        <div dangerouslySetInnerHTML={{ __html: paper.abstract }} />
      </div>

      <div>
        <StyledHeading>Materials</StyledHeading>
        <Materials
          materials={paper.materials}
          award={paper.award}
          bibTex={[paper.bibTex, paper.urlId]}
        />
      </div>

      <div>
        <StyledHeading>Authors</StyledHeading>
        <NameCards names={paper.authors} people={people} />
      </div>

      <div>
        <StyledHeading>Citation</StyledHeading>
        <Citation pub={paper} people={people} showDoi={true} />
      </div>
    </Stack>
  );
};

const AddEditPaper = ({ papers }) => {
  const [completePaperJson, setCompletePaperJson] = useState(null);

  function handleSubmit(e) {
    // Prevent the browser from reloading the page
    e.preventDefault();

    // Read the form data
    const form = e.target;
    const formData = new FormData(form);

    const paperJson = Object.fromEntries(formData.entries());

    let newPaperJson = {}; // The rewritten version to upload

    // rewrite a bit while copying
    let switchToMaterials = false;
    for (const key in paperJson) {
      if (paperJson.hasOwnProperty(key)) {
        switch (key) {
          case "authors": // Split comma-sep authors into an array
            newPaperJson.authors = paperJson.authors
              .split(",")
              .map((a) => a.trim());
            break;
          case "venue_select": // FALLS THROUGH
          case "venue_text": // Use the text venue if provided otherwise the selected one
            // This will happen twice, but no ill effect
            let newVenue = "";
            if (
              paperJson.venue_select === "none" ||
              paperJson.venue_text !== ""
            ) {
              newVenue = paperJson.venue_text;
            } else {
              newVenue = paperJson.venue_select;
            }
            newPaperJson.venue = newVenue;
            break;
          case "number": // FALLS THROUGH
          case "pages": // FALLS THROUGH
          case "volume": // FALLS THROUGH
            break; // Skip these three fields
          case "PDF": // make this and all following as materials
            let materials = {};
            materials[key] = paperJson[key];
            newPaperJson.materials = materials;
            switchToMaterials = true;
            break;
          default:
            if (!switchToMaterials) {
              // We just copy over until we get to materials
              newPaperJson[key] = paperJson[key];
            } else {
              newPaperJson.materials[key] = paperJson[key];
            }
        }
      }
    }

    // Create the urlId and bibtex key to use
    // [auth][year][shorttitle:capitalize] bibtex key generator from JabRef detailed at
    // https://docs.jabref.org/setup/citationkeypatterns

    // [auth]
    let auth = "";
    if (newPaperJson.authors.length > 0 && newPaperJson.authors[0] !== "") {
      // Get the surname of the first author
      auth = newPaperJson.authors[0].split(" ").slice(-1)[0];
    }

    // [year] // newPaperJson.year

    // [shorttitle:capitalize]  // First 3 words that aren't function words. capitalized
    const functionWords = [
      "a",
      "about",
      "above",
      "across",
      "against",
      "along",
      "among",
      "an",
      "and",
      "around",
      "at",
      "before",
      "behind",
      "below",
      "beneath",
      "beside",
      "between",
      "beyond",
      "but",
      "by",
      "down",
      "during",
      "except",
      "for",
      "for",
      "from",
      "in",
      "inside",
      "into",
      "like",
      "near",
      "nor",
      "of",
      "off",
      "on",
      "onto",
      "or",
      "since",
      "so",
      "the",
      "through",
      "to",
      "toward",
      "under",
      "until",
      "up",
      "upon",
      "with",
      "within",
      "without",
      "yet",
    ];

    let shortTitleNoPunc = newPaperJson.title;
    shortTitleNoPunc = shortTitleNoPunc.replace(
      // eslint-disable-next-line
      /[.,\/#!$%\^&\*;:{}=\-_`~()—–]/g,
      ""
    );
    shortTitleNoPunc = shortTitleNoPunc.replace(/\s{2,}/g, " ");

    let shortTitleArray = shortTitleNoPunc.split(" ");
    shortTitleArray = shortTitleArray.filter(
      (w) => !functionWords.includes(w.toLowerCase())
    );
    shortTitleArray = shortTitleArray.slice(0, 3).map((w) => {
      w = w.trim();
      let capitalized = w.charAt(0).toUpperCase() + w.slice(1);
      return capitalized;
    });
    let shortTitle = shortTitleArray.join("");
    let key = auth + newPaperJson.year + shortTitle;
    newPaperJson = Object.assign({ urlId: key }, newPaperJson);

    let teaserImg = "";
    if (key !== "") {
      teaserImg = key + ".png";
    }
    if (newPaperJson.teaserImg !== "") {
      teaserImg = teaserImg + "—file at:" + newPaperJson.teaserImg;
    }
    newPaperJson.teaserImg = teaserImg;

    let image = "";
    if (key !== "") {
      image = key + ".png";
    }
    if (newPaperJson.image !== "") {
      image = image + "—file at:" + newPaperJson.image;
    }
    newPaperJson.image = image;

    if (newPaperJson.materials.DOI !== "") {
      newPaperJson.materials.DOI =
        "https://doi.org/" + newPaperJson.materials.DOI;
    }

    let series = "";
    let venueSplit = newPaperJson.venue.split("—");
    if (venueSplit.length > 1) {
      series = venueSplit.slice(-1); //Last bit behind —
    }

    let note = "XXX YYYY."; // Manually fix
    if (newPaperJson.award !== "") {
      note += " " + newPaperJson.award;
    }
    if (newPaperJson.Preprint !== "") {
      note += " Preprint at \\url{" + newPaperJson.materials.Preprint + "}.";
    }
    if (newPaperJson.Supplement !== "") {
      note +=
        " Supplemental material at \\url{" +
        newPaperJson.materials.Supplement +
        "}.";
    }

    let bibtex = "\n\n";
    bibtex += "@XXXXX{" + key + ",\n"; // Pick manually later
    bibtex += "  author   = {" + paperJson.authors + "},\n"; // Not normalized to BibTeX format! Pick manually later
    bibtex += "  VENUE    = {" + newPaperJson.venue + "},\n"; // Pick manually later
    bibtex += "  title    = {" + paperJson.title + "},\n";
    bibtex += "  year     = {" + paperJson.year + "},\n";
    bibtex += "  note     = {" + note + "},\n";
    bibtex += "  number   = {" + paperJson.number + "},\n";
    bibtex += "  pages    = {" + paperJson.pages + "},\n";
    bibtex += "  volume   = {" + paperJson.volume + "},\n";
    bibtex += "  abstract = {" + paperJson.abstract + "},\n";
    bibtex += "  doi      = {" + paperJson.DOI + "},\n";
    bibtex += "  series   = {" + series + "},\n";
    bibtex += "}\n";

    newPaperJson.bibtex = bibtex;

    setCompletePaperJson(newPaperJson);
  }

  const [agreeCheckbox, setAgreeCheckbox] = useState(false);

  function buttonHandler() {
    let status = agreeCheckbox;
    setAgreeCheckbox(!status);
  }

  return (
    <div>
      <h3>Add or edit a paper</h3>
      <Helmet>
        <title>
          Add or edit a paper - Khoury Vis Lab, Northeastern
          University
        </title>
        <meta
          name="description"
          content={
            "Add or edit a paper - Khoury Vis Lab, Northeastern University"
          }
        />
      </Helmet>

      <p>Please enter ALL details for your paper here.</p>

      {/* WARNING!! Do not reorder these fields w/o checking the logic for creating materials above */}
      <form method="post" onSubmit={handleSubmit}>
        <hr />
        <h4>Required metadata</h4>
        <p>
          <label>
            <strong>Title</strong> —{" "}
            <strong>
              <em>Lower case</em>
            </strong>{" "}
            except for proper nouns. <br />
            <textarea name="title" rows={1} cols={120} />
          </label>
        </p>
        <p>
          <label>
            <strong>Year</strong> — First year of publication. E.g., date of VIS
            not later TVCG appearance.
            <br />
            <textarea name="year" rows={1} cols={120} />
          </label>
        </p>
        <p>
          <label>
            <strong>Authors</strong> — Comma-separated with ". " after initials,
            "~" for surnames with spaces. E.g., "Cody Dunne, Sara Di~Bartolomeo,
            Michelle A. Borkin".
            <br />
            <textarea name="authors" rows={1} cols={120} />
          </label>
        </p>
        <p>
          <label>
            <strong>Venue</strong> — Pick one of our common venues or write in
            your own.
            <br />
            <select name="venue_select">
              <option value="none">Pick a venue (or leave if none work)</option>

              <optgroup label="Journal-first" />
              <option value="IEEE Transactions on Visualization and Computer Graphics—TVCG">
                TVCG
              </option>
              <option value="Computer Graphics Forum—CGF">CGF</option>
              <option value="IEEE Computer Graphics and Applications—CGA">
                CGA
              </option>
              <option value="Information Visualization—IVI">
                IVI (Information Visualization)
              </option>
              <option value="ACM Transactions on Computer-Human Interaction—TOCHI">
                TOCHI
              </option>

              <optgroup label="Conference-then-journal" />
              <option value="IEEE Transactions on Visualization and Computer Graphics—VIS/TVCG">
                VIS/TVCG
              </option>
              <option value="Computer Graphics Forum—EuroVis/CGF">
                EuroVis/CGF
              </option>
              <option value="IEEE Transactions on Visualization and Computer Graphics—PacificVis/TVCG">
                PacificVis/TVCG
              </option>
              <option value="Proc. ACM on Management of Data—SIGMOD/PACMMOD">
                SIGMOD/PACMMOD
              </option>

              <optgroup label="Conference-only *papers*" />
              <option value="Proc. CHI Conference on Human Factors in Computing Systems—CHI">
                CHI
              </option>
              <option value="Proc. CHI Conference on Human Factors in Computing Systems Late-Breaking Work—CHI LBW">
                CHI LBW
              </option>
              <option value="Proc. IEEE Visualization Conference—VIS">
                VIS
              </option>
              <option value="Proc. EuroVis Conference on Visualization—EuroVis">
                EuroVis
              </option>
              <option value="Proc. ACM SIGMOD International Conference on Management of Data—SIGMOD">
                SIGMOD
              </option>
              <option value="Proc. Graph Drawing and Network Visualization—GD">
                GD
              </option>

              <optgroup label="Workshops, posters" />
              <option value="Proc. alt.VIS workshop at IEEE VIS—alt.VIS">
                alt.VIS
              </option>
              <option value="Proc. Workshop on Visualization for the Digital Humanities—VIS4DH">
                VIS4DH
              </option>
              <option value="Poster at IEEE VIS—VIS Posters">
                VIS Posters
              </option>
              <option value="Proc. Extended Abstracts of CHI Conference on Human Factors in Computing Systems—CHI EA">
                CHI EA
              </option>
            </select>
          </label>
          <label>
            Write in your own venue if none of the options work.
            <br />
            <textarea name="venue_text" rows={1} cols={120} />
          </label>
        </p>
        <p>
          <label>
            <strong>Abstract</strong> — Make URLs HTML hyperlinks (i.e., "
            {'<a href="https://osf.io/XXXXX">osf.io/XXXXX</a>'}").
            <br />
            <textarea name="abstract" rows={8} cols={120} />
          </label>
        </p>
        <hr />
        <h4>Images</h4>
        <p>
          <label>
            <strong>Teaser image</strong> — Public URL to a small 640x360px PNG
            thumbnail for lists w/o surrounding whitespace (except either
            vertically or horizontally to get dimensions) and no transparency (
            <a href="https://drive.google.com/drive/">GDrive</a>,{" "}
            <a href="https://imgur.com">Imgur</a>—direct links are not
            required).
            <br />
            <textarea name="teaserImg" rows={1} cols={120} />
          </label>
        </p>
        <p>
          <label>
            <strong>Main image</strong> — Public URL to a large approx
            1920x1080px PNG figure (try to match the width) for the paper w/o
            peripheral whitespace and no transparency (
            <a href="https://drive.google.com/drive/">GDrive</a>,{" "}
            <a href="https://imgur.com">Imgur</a>—direct links are not
            required).
            <br />
            <textarea name="image" rows={1} cols={120} />
          </label>
        </p>
        <p>
          <label>
            <strong>Main image caption</strong> — Caption for the main image for
            the paper page.
            <br />
            <textarea name="imageDesc" rows={1} cols={120} />
          </label>
        </p>
        <p>
          <label>
            <strong>Main image alt text</strong> — Descriptive alt text for the
            main image to make it more accessible.
            <br />
            <textarea name="altText" rows={1} cols={120} />
          </label>
        </p>
        <hr />
        <h4>Optional metadata</h4>
        <p>
          <label>
            <strong>Number</strong> — E.g., "1".
            <br />
            <textarea name="number" rows={1} cols={120} />
          </label>
        </p>
        <p>
          <label>
            <strong>Pages</strong> — E.g., "324--334".
            <br />
            <textarea name="pages" rows={1} cols={120} />
          </label>
        </p>
        <p>
          <label>
            <strong>Volume</strong> — E.g., "28".
            <br />
            <textarea name="volume" rows={1} cols={120} />
          </label>
        </p>
        <p>
          <label>
            <strong>Award</strong> — Did your work receive an award? E.g.,
            "alt.VIS 2022 Most Dystopian award!".
            <br />
            <textarea name="award" rows={1} cols={120} />
          </label>
        </p>
        <hr />
        <h4>Required links</h4>
        Enter each of the following.
        <p>
          <label>
            <strong>PDF</strong> — PDF from{" "}
            <a href="https://osf.io/preprints/">OSF preprint</a>. E.g.,
            "https://osf.io/XXXXX/download".
            <br />
            <textarea name="PDF" rows={1} cols={120} />
          </label>
        </p>
        <p>
          <label>
            <strong>Preprint</strong> —{" "}
            <a href="https://osf.io/preprints/">OSF preprint</a> link. E.g.,
            "https://osf.io/XXXXX/".
            <br />
            <textarea name="Preprint" rows={1} cols={120} />
          </label>
        </p>
        <hr />
        <h4>Optional links</h4>
        Enter as many of the following links as you can.
        <p>
          <label>
            <strong>DOI</strong> — For the{" "}
            <strong>
              <em>official publisher version</em>
            </strong>
            . Don't include the URL prefix.
            <br />
            <textarea name="DOI" rows={1} cols={120} />
          </label>
        </p>
        <p>
          <label>
            <strong>OSF supplement</strong> — E.g., "https://osf.io/XXXXX/".
            <br />
            <textarea name="Supplement" rows={1} cols={120} />
          </label>
        </p>
        <p>
          <label>
            <strong>Preregistration</strong> — E.g., "https://osf.io/XXXXX/".
            <br />
            <textarea name="Preregistration" rows={1} cols={120} />
          </label>
        </p>
        <p>
          <label>
            <strong>Code</strong> — Ideally GitHub, with your code already
            mirrored on OSF. E.g.,
            "https://github.com/VisDunneRight/IA-Design-Space".
            <br />
            <textarea name="Code" rows={1} cols={120} />
          </label>
        </p>
        <p>
          <label>
            <strong>Homepage</strong> — E.g.,
            "https://visdunneright.github.io/IDMVis/".
            <br />
            <textarea name="Homepage" rows={1} cols={120} />
          </label>
        </p>
        <p>
          <label>
            <strong>Video preview</strong> — A short conference video. E.g.,
            "https://www.youtube.com/watch?v=xxXxxXXXXXx".
            <br />
            <textarea name="Video preview" rows={1} cols={120} />
          </label>
        </p>
        <p>
          <label>
            <strong>Demo video</strong> — A longer demonstration of your tool or
            study. E.g., "https://www.youtube.com/watch?v=xxXxxXXXXXx".
            <br />
            <textarea name="Demo video" rows={1} cols={120} />
          </label>
        </p>
        <p>
          <label>
            <strong>Video presentation</strong> — The presentation video or
            recording of it. E.g.,
            "https://www.youtube.com/watch?v=xxXxxXXXXXx".
            <br />
            <textarea name="Video presentation" rows={1} cols={120} />
          </label>
        </p>
        <p>
          <label>
            <strong>Award Link</strong> — Link supporting any award.
            <br />
            <textarea name="Award" rows={1} cols={120} />
          </label>
        </p>
        <p>
          <label>
            <strong>Reproducibility Report</strong> — Is there any official
            reproduction of your work? Give the URL.
            <br />
            <textarea name="Reproducibility report" rows={1} cols={120} />
          </label>
        </p>
        <p>
          <label>
            <strong>University News</strong> — URL for any College or University
            news item.
            <br />
            <textarea name="University news" rows={1} cols={120} />
          </label>
        </p>
        <p>
          <label>
            <strong>News</strong> — URL for any external news item.
            <br />
            <textarea name="News" rows={1} cols={120} />
          </label>
        </p>
        <p>
          <label>
            <strong>Other links</strong> — List all other links as
            comma-separated arrays. E.g., "[Study 1 video,
            https://osf.io/XXXXX],[Award 2, https://awardcentral.com/...]".{" "}
            <br />
            <textarea name="Other links" rows={1} cols={120} />
          </label>
        </p>
        <hr />
        <h4>Submitting</h4>
        <label>
          <input
            type="checkbox"
            defaultChecked={false}
            checked={agreeCheckbox}
            onChange={() => buttonHandler()}
          />{" "}
          I have checked that all required fields are filled in correctly and as
          many optional fields as possible are filled. <br />I acknowledge that
          the web developers may be grouchy and slow if too many back-and-forth
          corrections are required.
        </label>
        <Button
          type="submit"
          className="mt-3 d-block"
          disabled={!agreeCheckbox}
        >
          Step 1: Generate JSON
        </Button>
      </form>

      <PaperJsonDisplay paperJson={completePaperJson} />
    </div>
  );
};

const copyToClipboard = async (text) => {
  await navigator.clipboard.writeText(text);
};

const PaperJsonDisplay = ({ paperJson }) => {
  const [show, setShow] = useState(false);

  if (!paperJson) {
    return null;
  }

  let paperOutput = JSON.stringify(paperJson, null, 2);

  return (
    <div>
      <Stack direction="horizontal" gap={3} className="mt-2">
        <Button
          onClick={(e) => {
            copyToClipboard(paperOutput);
            setShow(true);
          }}
        >
          Step 2: Copy to clipboard <ImCopy />
        </Button>
        <StyledPaperToast
          onClose={() => setShow(false)}
          show={show}
          delay={5000}
          autohide
        >
          <StyledPaperToastBody>
            <MdCheckCircle color={"green"} size={24} />
            <span>Copied paper JSON to clipboard. Now do step 3!</span>
          </StyledPaperToastBody>
        </StyledPaperToast>
      </Stack>

      <Button
        className="mt-2"
        onClick={(e) => {
          let newPaperIssueUrl =
            "https://github.com/KhouryVis/khouryvis.github.io/issues/new?assignees=codydunne%2C+DanielKerrigan&labels=new_paper&projects=&template=new-paper-template.md&title=[NEW PAPER] ";
          let customizedNewPaperIssueUrl = newPaperIssueUrl + paperJson.urlId;
          window.open(customizedNewPaperIssueUrl);
        }}
      >
        Step 3: Paste into new issue <br />
        (you need to log in to GitHub and <br />
        be in the KhouryVis organization)
      </Button>

      <StyledPre>{paperOutput}</StyledPre>
    </div>
  );
};

const DisplayBibtex = ({ title, bibtex }) => {
  const [show, setShow] = useState(false);
  return (
    <div>
      <h3>{title}</h3>
      <Button
        variant="light"
        onClick={(e) => {
          copyToClipboard(bibtex);
          setShow(true);
        }}
      >
        <ImCopy />
      </Button>
      <StyledPre>{bibtex}</StyledPre>
      <StyledToast
        onClose={() => setShow(false)}
        show={show}
        delay={3000}
        autohide
      >
        <Toast.Body>
          <MdCheckCircle color={"green"} size={25} /> Copied BibTex to
          clipboard.
        </Toast.Body>
      </StyledToast>
    </div>
  );
};

const BibTex = ({ papers, BibTexFile }) => {
  let { paperId } = useParams();
  let paper = papers.find((p) => p.urlId === paperId);

  if (paper.bibTex) {
    let bibtex = paper.bibTex.replace(/},\s?/g, "},\n");
    return <DisplayBibtex title={paper.title} bibtex={bibtex} />;
  } else {
    if (paperId in BibTexFile) {
      return <DisplayBibtex title={paper.title} bibtex={BibTexFile[paperId]} />;
    } else {
      return <></>;
    }
  }
};

const Papers = ({ papers, people }) => {
  // Filter to just Cody's papers
  papers = papers.filter(paper => paper.authors.includes("Cody Dunne"))


  papers.sort(function (a, b) {
    return b.year - a.year;
  });

  const ShowHeaderYear = ({ pub, prevPub }) => {
    if (prevPub === undefined || prevPub.year !== pub.year) {
      return <StyledYear>{pub.year}</StyledYear>;
    } else {
      return <></>;
    }
  };
  return (
    <>
      Here are my publications since joining Northeastern.
      Please see <a href="https://scholar.google.com/citations?user=qDjyCz8AAAAJ">Google Scholar</a> and <StyledLink href="/dunne_cv.pdf">my CV</StyledLink> for a complete listing.

      {papers.map((pub, index) => (
        <React.Fragment key={index}>
          <ShowHeaderYear pub={pub} prevPub={papers[index - 1]} />
          <Citation pub={pub} people={people} />
        </React.Fragment>
      ))}
    </>
  );
};

const Publications = ({ papers, people, BibTexFile }) => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <StyledTitle id="papers">Publications</StyledTitle>
        <Papers papers={papers.publications} people={people} />
      </Route>
      <Route exact path={`${path}/add`}>
        <AddEditPaper papers={papers.publications} />
      </Route>
      <Route path={`${path}/bibtex/:paperId`}>
        <BibTex papers={papers.publications} BibTexFile={BibTexFile} />
      </Route>
      <Route path={`${path}/:paperId`}>
        <Paper papers={papers.publications} people={people} />
      </Route>
    </Switch>
  );
};

export default Publications;
