import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Authors from "../../components/Authors";
import {
  StyledPostTitle,
  StyledPostH1,
  StyledPostH2,
  StyledPostH3,
  BlogPostContainer,
  BlogPostContent,
  StyledBlockQuote,
} from "./styles";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import NotFound from "../NotFound";

const BlogPost = ({ posts, people }) => {
  let { postId } = useParams();

  let post = posts.find((p) => p.slug === postId);

  const [postContent, setPostContent] = useState("");

  useEffect(() => {
    if (post !== undefined) {
      fetch(`${process.env.PUBLIC_URL}/BlogPosts/${post.slug}.md`)
        // note that if postContentUrl does not exist, the response will still
        // be okay, because our 404 pages do not set the 404 status code
        .then((response) => (response.ok ? response.text() : Promise.reject()))
        // this means that if the markdown file doesn't exist, content will
        // be filled with the HTML of the 404 page.
        .then((content) => setPostContent(content))
        .catch(() => setPostContent("Error loading blog post content."));
    }
  }, [post]);

  if (post !== undefined) {
    return (
      <BlogPostContainer>
        <BlogPostContent>
          <StyledPostTitle>{post.title}</StyledPostTitle>
          <h5>
            <Authors names={post.authors} people={people} />
          </h5>
          <h5>{post.date}</h5>
          <ReactMarkdown
            children={postContent}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            components={{
              h1: ({ node, ...props }) => (
                <StyledPostH1 id={generateSlug(props.children[0])} {...props}></StyledPostH1>
              ),
              h2: ({ node, ...props }) => (
                <StyledPostH2 id={generateSlug(props.children[0])} {...props}></StyledPostH2>
              ),
              h3: ({ node, ...props }) => (
                <StyledPostH3 id={generateSlug(props.children[0])} {...props}></StyledPostH3>
              ),
              blockquote: StyledBlockQuote
            }}
          />
        </BlogPostContent>
      </BlogPostContainer>
    );
  } else {
    return <NotFound />;
  }
};

const generateSlug = (string) => {
  let str = string.replace(/^\s+|\s+$/g, "");
  str = str.toLowerCase();
  str = str
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");
  return str;
};

export default BlogPost;
