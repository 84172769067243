import styled from "styled-components";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export const StyledHeading = styled.div`
  font-size: 15px;
  font-weight: 600;
  padding-top: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const PostTeaserTitle = styled(Link)`
  font-weight: 600;
  font-size: 24px;
  color: #FFF;
  text-decoration: none;
  &:hover {
    color: #ffbf3d;
    text-decoration: none;
  }
`;

export const PostRow = styled(Row)`
  padding: 10px 0px;
`;

export const StyledPostTitle = styled.h1`
  text-transform: uppercase;
  font-size: 36px;
`;

export const BlogPostContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const BlogPostContent = styled.div`
  max-width: 55em;
`;

// blog post content styles

export const StyledPostH1 = styled.h1`
  font-size: 32px;
`;

export const StyledPostH2 = styled.h2`
  font-size: 28px;
`;

export const StyledPostH3 = styled.h3`
  font-size: 24px;
`;

// see https://github.com/remarkjs/react-markdown/issues/35
export const StyledBlockQuote = styled.blockquote`
  color: #FFF;
  background-color: #4a4a49;
  margin: 1em;
  padding-left: .7em;
  padding-top: .7em;
  padding-bottom: .01em;
  border-left: 0.2em rgba(255,255,255,.7) solid;
`;